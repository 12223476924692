.App {
  text-align: center;
  position: relative;
  background-image: url("../src/assets/hbd.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
}

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer {
  margin-top: 80px;
}

.footer,
.link {
  display: flex;
  align-items: center;
  margin-right: 16px;
  color: black;
}

.link {
  font-size: 1rem;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.footer-icon {
  padding: 0 12px;
}

.btn {
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 16px 32px;
  background: #0078c1;
  border: 4px solid #004e84;
  border-radius: 100px;
  color: white;
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  z-index: 10000;
}

@media (max-width: 800px) {
  .App {
    background-image: url("../src/assets/hbd-mobile.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media (min-width: 481px) {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo {
  height: 40vw;
  width: 40vw;
  min-width: 300px;
  min-height: 300px;
  max-width: 600px;
  max-height: 600px;
  z-index: 10000;
}
